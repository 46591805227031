import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import Layout from "../components/layouts";
import { graphql } from "gatsby";
import Item from "../components/video/Item";
import { shuffle } from "../utils/common";
import { generateCDN } from "../utils/common";

const opts = {
  height: 529,
  width: "100%",
  playerVars: {
    autoplay: 1,
  },
};
export default function Video({ data: { sanityVideos }, location }) {
  const [other, setOther] = useState([]);
  const [list, setList] = useState([]);
  const [play, setPlay] = useState(false);
  useEffect(() => {
    if (location?.state?.other) {
      const filter = location?.state?.other.filter(
        (item) =>
          item.node?.slug?.current?.toLowerCase() !==
          sanityVideos.videoId?.toLowerCase()
      );

      setOther(shuffle(filter));
      setList(location?.state?.other);
    }
  }, []);

  const onReady = (evt) => {
    evt.target.playVideo();
  };

  return (
    <Layout footer={false}>
      <div
        // style={{
        //   backgroundImage: `url(${bg})`,
        //   backgroundRepeat: "no-repeat",
        //   position: "relative",
        //   backgroundPosition: "inherit",
        //   backgroundSize: "cover",
        // }}
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(69,204,55,0.6), rgba(41,217,211,0.6)`,
        }}
      >
        <div className="max-w-[1366px] w-full mx-auto pt-[120px] pb-10 ">
          <div className="grid grid-cols-1 lg:grid-cols-12 px-5">
            <div className="col-span-8">
              <div className="relative h-[250px] sm:h-[460px] lg:h-[529px] rounded-[28px] cursor-pointer overflow-hidden">
                {play ? (
                  <YouTube
                    videoId={sanityVideos?.videoId}
                    opts={opts}
                    loading="eager"
                    playing={true}
                    className="bg-black"
                    iframeClassName="h-[250px] sm:h-[460px] lg:h-[529px]"
                    onReady={onReady}
                  />
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${generateCDN(
                        sanityVideos.thumbnail.asset.url
                      )})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                    className="relative bg-black h-[250px] sm:h-[460px] lg:h-[529px] rounded-[28px] cursor-pointer overflow-hidden"
                  >
                    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/20" />
                    <div className="absolute z-30 top-[39%] sm:top-[45%] left-[41%] sm:left-[47%]">
                      <svg
                        width="69"
                        height="79"
                        viewBox="0 0 69 79"
                        fill="none"
                        onClick={() => setPlay(true)}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.6684 1.04136L66.5719 37.1537C67.0155 37.4134 67.3835 37.7847 67.6392 38.2306C67.8948 38.6766 68.0294 39.1817 68.0294 39.6957C68.0294 40.2097 67.8948 40.7148 67.6392 41.1608C67.3835 41.6067 67.0155 41.978 66.5719 42.2377L4.6684 78.3501C4.2199 78.6118 3.71018 78.7503 3.1909 78.7515C2.67161 78.7528 2.16123 78.6167 1.71148 78.3572C1.26172 78.0976 0.888581 77.7237 0.62987 77.2735C0.371159 76.8232 0.236075 76.3126 0.238309 75.7933V3.58631C0.238155 3.06803 0.374755 2.55888 0.634314 2.11028C0.893873 1.66168 1.2672 1.28951 1.7166 1.03134C2.16601 0.773171 2.67557 0.63815 3.19385 0.63991C3.71213 0.641669 4.22076 0.780146 4.6684 1.04136Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-[50px] px-[23px] pb-[30px]">
                <p className="text-2xl leading-[34px] font-normal font-primary ">
                  {sanityVideos?.title}
                </p>

                <p className="mt-[9px] font-poppins font-normal text-base leading-6">
                  {sanityVideos?.description}
                </p>
              </div>
            </div>
            <div className="col-span-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-5 gap-y-[47px] lg:pl-16">
              {other.map(({ node }, index) => (
                <Item item={node} key={index} list={list} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($videoId: String!) {
    sanityVideos(id: { eq: $videoId }) {
      seo {
        description
        keywords
        ldSchema
        pagehandle
        title
      }
      description
      id
      title
      videoId
      thumbnail {
        asset {
          url
        }
      }
    }
  }
`;
