import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { truncate } from "../../utils/common";

export default function item({ item, list }) {
  return (
    <div className="rounded-[22px] border-[0.3px] h-[360px] overflow-hidden border-black max-w-[336px] w-full">
      <Link to={`/video/${item?.slug?.current}`} state={{ other: list }}>
        <div className="relative -m-[0.5px] cursor-pointer">
          <div className="overflow-hidden video_rounded">
            <GatsbyImage
              image={item?.thumbnail?.asset?.gatsbyImageData}
              alt={item?.thumbnail?.alt}
              loading="lazy"
            />
          </div>
          <div className="absolute z-30 top-[40%] left-[45%] ">
            <svg
              width="42"
              height="48"
              viewBox="0 0 42 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.77004 0.483625L40.4123 22.4428C40.682 22.6007 40.9058 22.8265 41.0612 23.0976C41.2167 23.3688 41.2985 23.6759 41.2985 23.9885C41.2985 24.3011 41.2167 24.6082 41.0612 24.8794C40.9058 25.1506 40.682 25.3764 40.4123 25.5343L2.77004 47.4934C2.49731 47.6526 2.18736 47.7368 1.8716 47.7376C1.55583 47.7383 1.24548 47.6556 0.971992 47.4978C0.698506 47.3399 0.471606 47.1126 0.314289 46.8388C0.156972 46.565 0.0748301 46.2545 0.0761885 45.9387V2.03115C0.076095 1.716 0.159159 1.4064 0.316991 1.13361C0.474824 0.86083 0.701837 0.634519 0.97511 0.477532C1.24838 0.320546 1.55824 0.238442 1.87339 0.239512C2.18855 0.240582 2.49784 0.324787 2.77004 0.483625Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </Link>
      <div className="mt-[27px] px-[23px] pb-[30px]">
        <p className="text-[20px] leading-[29px] font-normal font-primary ">
          {truncate(item.title, 60)}
        </p>
        <div className="flex items-center mt-[5px]">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_42_535)">
              <rect width="22.0261" height="22.0261" fill="black" />
              <path
                d="M7.11398 4.99174L16.7577 10.6176C16.8269 10.658 16.8842 10.7159 16.924 10.7853C16.9638 10.8548 16.9848 10.9335 16.9848 11.0136C16.9848 11.0937 16.9638 11.1724 16.924 11.2418C16.8842 11.3113 16.8269 11.3691 16.7577 11.4096L7.11398 17.0354C7.04411 17.0762 6.9647 17.0978 6.88381 17.098C6.80291 17.0982 6.7234 17.077 6.65333 17.0365C6.58327 16.9961 6.52514 16.9379 6.48483 16.8677C6.44453 16.7976 6.42348 16.718 6.42383 16.6371V5.38821C6.42381 5.30747 6.44509 5.22815 6.48552 5.15827C6.52596 5.08838 6.58412 5.0304 6.65413 4.99018C6.72414 4.94996 6.80353 4.92893 6.88427 4.9292C6.96501 4.92948 7.04425 4.95105 7.11398 4.99174Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_42_535">
                <rect width="22.0261" height="22.0261" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="ml-[6px] font-poppins font-normal">{item.length}</p>
        </div>
      </div>
    </div>
  );
}
